<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
     <!-- <pageIndex></pageIndex> -->
     <!-- <pcIndex></pcIndex> -->
      <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import pcIndex from '@/views/pc.vue' 
// import pageIndex from '@/views/pageIndex.vue' 
// import router from './router';
export default {
  name: 'App',
  components: {
    // HelloWorld,
    // pageIndex,
    // pcIndex
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body {
  margin: 0;
  padding: 0;
  /* background: #9B9A9A; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
