import Vue from 'vue'
import VueRouter from 'vue-router'


 import PageIndex from '@/views/pageIndex.vue' 

import PhonePage from '@/views/phone.vue'
Vue.use(VueRouter)
const routes = [
    {
    path: '/phone',
    name: 'phone',
    component: PhonePage
  },
  {
    path: '/',
    name: 'pageIndex',
    component: PageIndex
  }



]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
