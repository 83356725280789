<template>
	<div class="phone_box">
	
				<div class="pageOne">
					<div class="box_bgImg">
						<div class="headerbox">
							<div class="leftbox">
								<img src="../assets/images/logo.png" alt="" />
							</div>
							<div class="rightbox">
								<div class="topbox">
									<div class="title">多粒鲜果</div>
									<div class="line"></div>
									<div class="mall">
										<span>FRESH</span>
										<span>MALL</span>
									</div>
								</div>
								<div class="bbox">
									多粒鲜果优质生鲜自营商城
								</div>
							</div>
						</div>
						<div class="middlehome">
							<div class="middle_title">多粒鲜果</div>
							<div class="middle_text">Multiple fresh</div>
							<div class="middle_icon">
								<div class="phone">
									<img src="../assets/images/pc02.png" alt="" />
								</div>
								<div class="min">
									<img src="../assets/images/pc01.png" alt="" />
								</div>
							</div>
							<div class="middle_image">
								<div class="download">
									<img src="../assets/images/ios_download.png" alt="" />
								</div>
								<div class="download">
									<img src="../assets/images/android_download.png" alt="" />
								</div>
							</div>
						</div>
						<div class="foot">新鲜直达 美味到家</div>
					</div>
				</div>
			
				<div class="pageTwo">
					<div class="project">商城介绍
						<div class="project_img">
							<img src="../assets/images/pc03.png" alt="" />
						</div>
						<div class="project_des">Introduction</div>
					</div>
					<div class="project_box">
						<div class="project_list">
							<div class="project_item">
								<div class="item_text">
									<div class="point"></div>
									<div class="item_title">商城背景</div>
								</div>
								<div class="item_remark">
									后疫情时代，拉动了用户与生鲜电商行业使用粘性，生鲜电商行业日人均使用次数，日人均使用时长实现双增涨，涨幅均在20%以上。
								</div>
							</div>
							<div class="project_item">
								<div class="item_text">
									<div class="point"></div>
									<div class="item_title">商城介绍</div>
								</div>
								<div class="item_remark">
									本商城是通过线上购买的商业模式，快递方式发送到自提点，从家庭消费更多属于计划性消费这一底层逻辑出发，通过快递冷链配送，降低损耗和配送成本。
								</div>
							</div>
							<div class="project_item">
								<div class="item_text">
									<div class="point"></div>
									<div class="item_title">产品定位</div>
								</div>
								<div class="item_remark">
									多粒鲜果是一款自营生鲜配送电商商城，涵盖了水果蔬菜、肉禽鸡蛋、水产海鲜、日用百货等品类。依托高效的运营模式和完善的供应链体系，将为更多消费者带去新鲜、丰富、价优、安全的生鲜食材和配送服务。
								</div>
							</div>
						</div>
						<div class="picture">
							<img src="../assets/images/pc05.png" alt="" />
						</div>
					</div>
				</div>
		
				<div class="pageThree">
					<div class="product">产品优势
						<div class="product_img">
							<img src="../assets/images/pc03.png" alt="" />
						</div>
						<div class="product_des">Mood Board</div>
					</div>
					<div class="product_content">
						<div class="product_box">
							<div class="freshImg">
								<img src="../assets/images/pc04.png" alt="" />
								<div class="fresh_item">
									<div class="item_title">新鲜</div>
									<div class="item_desc">FRESH</div>
								</div>
							</div>
							<div class="freshImg">
								<img src="../assets/images/pc06.png" alt="" />
								<div class="fresh_item">
									<div class="item_title">丰富</div>
									<div class="item_desc">ABUNDANT</div>
								</div>
							</div>
							<div class="freshImg">
								<img src="../assets/images/pc07.png" alt="" />
								<div class="fresh_item">
									<div class="item_title">便捷</div>
									<div class="item_desc">CONVENIENT</div>
								</div>
							</div>
						</div>
						<div class="bottom">
							<!-- <div class="bottom_img">
								<img src="../assets/images/contact2.png" alt="" />
							</div> -->
							<div>©2024 四季有果 版权所有</div>
							<div>网站备案/许可证号: <span @click="toGongxinbu" class="span"> 鄂ICP备2024038352号-4</br>
									鄂ICP备2024038352号-5A
								</span></div>
							<div>地址：武汉市洪山区光谷五路恺德中心3栋</div>
							<div>联系我们：sijiyouguo2024@126.com</div>
							<div>
								<span class="bottom_span" @click="toPrv">《隐私协议》</span>
								<span>武汉四季有果科技有限公司</span>
							</div>
						</div>
					</div>
				</div>
			
		</el-carousel>
	</div>
</template>

<script>
	let screenWidth = document.documentElement.clientWidth //获取屏幕的宽度
	let centerWH = 750 / screenWidth //比例
	document.documentElement.style.fontSize = 1 / centerWH + 'px' //设置html根元素fontsize的大小

	export default {
		name: "pageIndex",
		data() {
			return {
				screenWidth: 0,
				screenHeight: 0,
				nowIndex: true
			};
		},
		created() {
			if (this.IsPC()) {
				this.$router.push("/");
			}
		},
		mounted() {
			//在mounted()方法里监听mousewheel；
			// chrome and ie
			window.addEventListener('mousewheel', this.handleScroll, false)
			//// firefox
			window.addEventListener("DOMMouseScroll", this.handleScroll, false)
			this.updateScreenSize();
			// 监听窗口大小变化事件  
			window.addEventListener('resize', this.updateScreenSize);
		},
		beforeDestroy() {

			// 组件销毁前移除事件监听器，避免内存泄漏  
			window.removeEventListener('resize', this.updateScreenSize);
		},
		methods: {
			handleScroll(e) {
				var that = this
				var direction = e.deltaY > 0 ? 'down' : 'up' //该语句可以用来判断滚轮是向上滑动还是向下
				if (this.nowIndex) {
					this.nowIndex = false
					if (direction == 'down') {
						console.log(that.$refs.carousel)
						if (typeof that.$refs.carousel !== 'undefined') {
							that.$refs.carousel.next();
						}
					} else {
						if (typeof that.$refs.carousel !== 'undefined') {
							that.$refs.carousel.prev();
						}

					}
					setTimeout(() => {
						that.nowIndex = true

					}, 200)
				}

			},
			updateScreenSize() {
				this.screenWidth = window.innerWidth;
				this.screenHeight = window.innerHeight;
			},
			// 判断是否是手机
			IsPC() {
				let userAgentInfo = navigator.userAgent;
				let Agents = [
					"Android",
					"iPhone",
					"SymbianOS",
					"Windows Phone",
					"iPad",
					"iPod",
				];
				let flag = true;
				for (let v = 0; v < Agents.length; v++) {
					if (userAgentInfo.indexOf(Agents[v]) > 0) {
						flag = false;
						break;
					}
				}
				return flag;
			},
			toPrv() {
				window.open("prvagg.html", '_blank');
			},
			toGongxinbu() {
				window.location.href = "https://beian.miit.gov.cn/"
			}
		},
	};
</script>

<style lang="less">
	.phone_box {

		.pageOne {
			background: linear-gradient(225deg, #99F1D4 0%, #ACF1C0 30%, #DCF5C5 75%, #DDF5C5 100%);
			position: relative;
			width: 100%;
			height: 100vh;
			/* 使用视口高度 */
			overflow: hidden;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.fullscreen-bg-video {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				z-index: -100;
				background-size: cover;
				overflow: hidden;
			}

			.box_bgImg {
				width: 684rem;
				height: 95vh;
				background-image: url('../assets/images/phonebg.png');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				// margin-top: 40rem;
				position: relative;

				.headerbox {
					display: flex;
					align-items: center;
					margin: 26rem 40rem;


					.leftbox {
						// display: flex;
						width: 100rem;
						height: 100rem;
						border-radius: 14rem;

						img {
							height: 100%;
							width: 100%;
							border-radius: 14rem;
						}

					}

					.rightbox {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-left: 10rem;

						.topbox {
							display: flex;
							align-items: center;
							margin-bottom: 6rem;

							.title {
								font-size: 44rem;
								color: #24BE2A;
								letter-spacing: 7rem;
								font-family: AlimamaDongFangDaKai-Regular;
							}

							.line {
								width: 1rem;
								height: 44rem;
								border: 1rem #24BE2A solid;
								margin: 10rem 20rem;

							}

							.mall {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: center;

								span {
									font-family: MiSans, MiSans;
									font-size: 16rem;
									color: #24BE2A;
									letter-spacing: 10rem;
								}
							}
						}

						.bbox {
							font-size: 20rem;
							color: #24BE2A;
							letter-spacing: 6rem;
							font-family: MiSans-Regular;
						}
					}

				}

				.middlehome {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.middle_title {
						margin-top: 120rem;
						font-size: 122rem;
						line-height: 10rem;
						color: #24BE2A;
						font-family: AlimamaDongFangDaKai-Regular;
						line-height: 80rem;
					}

					.middle_text {
						margin-top: 30rem;
						font-size: 60rem;
						color: #FFFFFF;
						font-family: MiSans-Regular;
						font-weight: bold;
						-webkit-text-stroke: 2px #24BE2A;
						letter-spacing: 2rem;

					}

					.middle_icon {
						width: 600rem;
						height: 540rem;
						position: relative;

						.phone {
							width: 500rem;
							height: 500rem;
							margin: 30rem auto;
							// margin-left: 30rem;
							// padding: 0 54rem;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.min {
							width: 136rem;
							height: 112rem;
							position: absolute;
							left: 0;
							bottom: 50rem;

							img {
								width: 100%;
								height: 100%;
							}
						}
					}

					.middle_image {
						display: flex;

						.download {
							margin-top: 20rem;
							margin-left: 20rem;

							img {
								width: 234rem;
								height: 78rem;
							}
						}
					}

				}

				.foot {
					font-family: MiSans, MiSans;
					font-weight: 500;
					font-size: 36rem;
					letter-spacing: 2rem;
					color: #55D2A2;
					// position: absolute;
					// left: 50rem;
					// bottom: 30rem;
					margin: 50rem auto;
				}
			}
		}

		.pageTwo {
			width: 100%;
			min-height: 100vh;
			background: linear-gradient(225deg, #FEFFF8 0%, #EDFFF4 53%, #E5F8F6 100%);
			padding: 96rem 50rem;
			box-sizing: border-box;

			.project {
				width: 208rem;
				height: 60rem;
				font-weight: 400;
				font-size: 50rem;
				color: #000000;
				text-align: left;
				letter-spacing: 2rem;
				-webkit-text-stroke: 1rem #000000;
				white-space: nowrap;
				position: relative;

				.project_img {
					width: 48rem;
					height: 48rem;
					position: absolute;
					bottom: -20rem;
					right: -20rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.project_des {
					font-weight: 400;
					font-size: 30rem;
					color: #FFFFFF;
					-webkit-text-stroke: 1.8rem #000000;
					letter-spacing: 3rem;
					font-style: italic;
				}
			}

			.project_box {
				margin-top: 100rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.project_list {
					.project_item {
						text-align: left;
						font-family: MiSans, MiSans;
						font-weight: 600;
						letter-spacing: 2rem;
						margin-bottom: 40rem;

						.item_text {
							display: flex;
							align-items: center;

							.item_title {
								font-size: 36rem;
								color: #000000;
								margin-left: 40rem;
								line-height: 90rem;
							}

							.point {
								width: 30rem;
								height: 30rem;
								background: #00D38B;
								border-radius: 50%;
							}
						}

						.item_remark {
							font-size: 24rem;
							color: #000000;
						}
					}

				}

				.picture {
					width: 378rem;
					height: 378rem;


					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.pageThree {
			width: 100%;
			min-height: 100vh;
			background: linear-gradient(225deg, #FEFFF8 0%, #EDFFF4 53%, #E5F8F6 100%);
			padding: 96rem 50rem;
			box-sizing: border-box;

			.product {
				width: 208rem;
				height: 60rem;
				font-weight: 400;
				font-size: 50rem;
				color: #000000;
				text-align: left;
				letter-spacing: 2rem;
				-webkit-text-stroke: 1rem #000000;
				white-space: nowrap;
				position: relative;

				.product_img {
					width: 48rem;
					height: 48rem;
					position: absolute;
					bottom: -20rem;
					right: -20rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.product_des {
					font-weight: 400;
					font-size: 30rem;
					color: #FFFFFF;
					-webkit-text-stroke: 1.8rem #000000;
					letter-spacing: 3rem;
					font-style: italic;
				}
			}

			.product_content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.product_box {
					margin-top: 100rem;

					.freshImg {
						height: 300rem;

						img {
							width: 316rem;
							height: 208rem;
						}

						.fresh_item {
							font-family: MiSans, MiSans;
							font-weight: 600;
							color: #07CE87;
							letter-spacing: 2rem;

							.item_title {
								font-size: 44rem;
							}

							.item_desc {
								font-size: 24rem;
							}
						}
					}
				}

				.bottom {
					font-family: MiSans, MiSans;
					font-size: 20rem;
					font-weight: 600;
					color: #07CE87;
					line-height: 50rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-top: 50rem;

					.bottom_img {
						width: 234rem;
						height: 76rem;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.span {
						color: #5990D0;
					}

					.bottom_span {
						color: #5990D0;
						margin-right: 20px;
					}
				}
			}
		}

		.bottom {
			margin-top: 130rem;
			line-height: 50rem;
			font-size: 20rem;
			color: #fff;
			font-family: MiSans-Semibold;

			.span {
				color: #5990D0;
			}

			.bottom_span {
				margin-right: 20rem;
				color: #5990D0;
			}
		}
	}
</style>